
import { agent } from "@/agent";
import ExpenseItem from "@/components/ExpenseItem.vue";
import { closeSharp, checkmark } from "ionicons/icons";
import { useFeature } from "@/composables/useFeature";
import { useNetwork } from "@/composables/useNetwork";
import ExpenseModal from "@/components/ExpenseModal.vue";
import EditModal from "@/components/EditModal.vue";
import toolbar from "@/components/Toolbar.vue";
import { useStorage } from "@/composables/useStorage";

import {
  IonPage,
  IonHeader,
  IonContent,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonList,
  IonItemGroup,
  IonItemDivider,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  IonLabel,
  IonButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  onIonViewWillEnter
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    EditModal,
    ExpenseItem,
    IonPage,
    IonHeader,
    IonContent,
    IonList,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonItemGroup,
    IonItemDivider,
    IonItemSliding,
    IonItemOption,
    IonItemOptions,
    IonLabel,
    IonButton,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    ExpenseModal,
    toolbar
  },
  setup() {
    // Constants
    const expenseGroups = ref();
    const selectedExpense = ref();
    const categories = ref([]);
    const expenseUrls: { uuid: string; urls: string[] }[] = []; // Track already loaded urls
    const expenseModal = ref(false);
    const editModal = ref(false);
    const { getUser } = useStorage();

    const { getAll, getAllCategories, getById, update } = agent();
    const { loading, raiseToast } = useFeature();
    const { isInternet } = useNetwork();

    const user = ref();

    onIonViewWillEnter(async () => {
      user.value = await getUser();
      categories.value = (await getAllCategories()).data;
    });

    // Methods / Computed
    const fetchItems = async () => {
      if (!isInternet.value) {
        return;
      }
      const res = await getAll({ statuses: ["pending"] });
      if (res && res.data) {
        expenseGroups.value = res.data;
      }
    };

    const doRefresh = async (event: any) => {
      await fetchItems();
      expenseUrls.length = 0;
      event.target.complete();
    };

    const handleOpenExpenseModal = async (expense: any) => {
      const loadingOverlay = await loading("Loading expense");
      loadingOverlay.present();

      try {
        const res = await getById(expense.publicId);
        if (res) {
          selectedExpense.value = res.data;
          expenseModal.value = true;
        }
      } catch (e) {
        if (e instanceof Error) {
          raiseToast(e.message, "error");
        }
      } finally {
        loadingOverlay.dismiss();
      }
    };

    const handleCloseExpenseModal = () => {
      if (expenseModal.value === true) {
        expenseModal.value = false;
      }
    };

    const handleCloseEditModal = async (update: boolean, expense: any) => {      

      if (editModal.value === true) {
        editModal.value = false;
      }
      if (update) {
        const loadingOverlay = await loading("Please wait. Updating expense");
        loadingOverlay.present();
        const { update } = agent();
        const res = await update(expense);
        await fetchItems().catch();
        if (res.status === 200) {
          await raiseToast("Updated Expense", "success");
        } else {
          await raiseToast("Error: " + res.message, "danger");
        }
        loadingOverlay.dismiss();
      }
    };

    const removeExpense = (reviewedExpense: any) => {
      const expenseGroup = expenseGroups.value.find((x: any) =>
        x.expenses.find((y: any) => y.id === reviewedExpense.id)
      );
      const index = expenseGroup.expenses.indexOf(reviewedExpense);
      expenseGroup.expenses.splice(index, 1);
      if (!expenseGroup.expenses.length) {
        expenseGroups.value.splice(
          expenseGroups.value.indexOf(expenseGroup),
          1
        );
      }
    };

    const handleApproveReject = async (args: any) => {
      if (!isInternet.value) {
        return;
      }
      if (
        !args ||
        !args.expense ||
        (args.approved !== true && args.approved !== false)
      ) {
        return;
      }
      handleCloseExpenseModal();
      const loadingOverlay = await loading("Please wait. Updating expense");
      loadingOverlay.present();
      args.expense.approved = args.approved;
      if (args.comment && args.comment.length) {
        args.expense.comment = args.comment;
      }
      const res = await update({...args.expense, category: { name: args.expense.category, organization: args.expense.organization }});
      if (res && res.status !== 200) {
        await raiseToast(res.message, "danger");
      } else {
        raiseToast(args.approved ? "Approved!" : "Rejected!", "success");
      }
      removeExpense(args.expense);
      loadingOverlay.dismiss();
    };

    // Lifecycle
    onIonViewWillEnter(async () => {
      const loadingOverlay = await loading("Please wait. Fetching expenses");

      if (!expenseGroups.value || !expenseGroups.value.length) {
        loadingOverlay.present();
      }
      await fetchItems();
      loadingOverlay.dismiss();
    });

    return {
      categories,
      closeSharp,
      checkmark,
      expenseGroups,
      editModal,
      expenseModal,
      selectedExpense,
      user,
      doRefresh,
      handleApproveReject,
      handleOpenExpenseModal,
      handleCloseExpenseModal,
      handleCloseEditModal
    };
  }
});
